/*
 * @Author: Rider
 * @Date: 2020-06-09 18:21:06
 * @LastEditors: Rider
 * @LastEditTime: 2021-03-31 11:23:39
 * @Description: file content
 */ 

import React from "react";

import Zmage from 'react-zmage';

import img1 from '../img/contactimg/contact1.jpeg';


class Contact extends React.Component {

  render() {
    return (
      <div>
        {/*<div class="contact">
        <div class="contact-head">About Chengzhuo</div>
        <div class="contact-content">Fashion Photographer {'&'} Designer</div>
        <div class="contact-content">based in London/Shanghai/Hangzhou</div>
        <br />
        <div class="contact-content">email: sookiezhuo0910@gmail.com</div>
        <div class="contact-content">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sookiezhuo0910@qq.com</div>
        <br />
        <div class="contact-content">instagram: @chengzhuoxu</div>
        <div class="contact-content">weibo:@卓哥z-</div>
        <div class="contact-content">wechat:417890394</div>
        </div>*/}
        <Zmage
          className="img"
          src={img1}
        />
      </div>
		);
  }
}

export default Contact;