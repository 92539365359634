/*
 * @Author: Rider
 * @Date: 2020-06-09 22:48:49
 * @LastEditors: Rider
 * @LastEditTime: 2021-03-30 21:24:09
 * @Description: file content
 */ 

import React from "react";

import Zmage from 'react-zmage';

import img1 from '../img/fashion/fashion5/1.jpg';
import img2 from '../img/fashion/fashion5/2.jpg';
import img3 from '../img/fashion/fashion5/3.jpg';
import img4 from '../img/fashion/fashion5/4.jpg';
import img5 from '../img/fashion/fashion5/5.jpg';
import img6 from '../img/fashion/fashion5/6.jpg';
import img7 from '../img/fashion/fashion5/7.jpg';
import img8 from '../img/fashion/fashion5/8.jpg';
import img9 from '../img/fashion/fashion5/9.jpg';
import img10 from '../img/fashion/fashion5/10.jpg';
import img11 from '../img/fashion/fashion5/11.jpg';
import img12 from '../img/fashion/fashion5/12.jpg';
import img13 from '../img/fashion/fashion5/13.jpg';
import img14 from '../img/fashion/fashion5/14.JPG';
import img15 from '../img/fashion/fashion5/15.jpg';

class Fashion5 extends React.Component {

  render() {
    return (
			<div class="box">
        <Zmage
          className="img"
          src={img1}
        />
        <Zmage
          className="img"
          src={img2}
        />
        <Zmage
          className="img"
          src={img3}
        />
        <Zmage
          className="img"
          src={img4}
        />
        <Zmage
          className="img"
          src={img5}
        />
        <Zmage
          className="img"
          src={img6}
        />
        <Zmage
          className="img"
          src={img7}
        />
        <Zmage
          className="img"
          src={img8}
        />
        <Zmage
          className="img"
          src={img9}
        />
        <Zmage
          className="img"
          src={img10}
        />
        <Zmage
          className="img"
          src={img11}
        />
        <Zmage
          className="img"
          src={img12}
        />
        <Zmage
          className="img"
          src={img13}
        />
        <Zmage
          className="img"
          src={img14}
        />
        <Zmage
          className="img"
          src={img15}
        />
      </div>
		);
  }
}

export default Fashion5;