/*
 * @Author: Rider
 * @Date: 2020-06-09 22:48:19
 * @LastEditors: Rider
 * @LastEditTime: 2020-06-09 22:48:27
 * @Description: file content
 */ 

import React from "react";

class Fashion3 extends React.Component {

  render() {
    return (
			<h1>Fashion3</h1>
		);
  }
}

export default Fashion3;