/*
 * @Author: Rider
 * @Date: 2020-10-08 21:43:01
 * @LastEditors: Rider
 * @LastEditTime: 2020-10-08 21:52:18
 * @Description: file content
 */
import React from "react";

class Video extends React.Component {

  render() {
    return (
	    <div class="contact">
        <div class="contact-content">Project: <a target="_blank" href="https://weibo.com/tv/show/1034:4515815106740246?from=old_pc_videoshow">https://weibo.com/tv/show/1034:4515815106740246?from=old_pc_videoshow</a></div>
      </div>
		);
  }
}

export default Video;