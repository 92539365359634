/*
 * @Author: Rider
 * @Date: 2020-10-08 21:00:45
 * @LastEditors: Rider
 * @LastEditTime: 2021-03-30 21:25:28
 * @Description: file content
 */
import React from "react";

import Zmage from 'react-zmage';

import img1 from '../img/fashion/fashion7/1.jpg';
import img2 from '../img/fashion/fashion7/2.jpg';
import img3 from '../img/fashion/fashion7/3.jpg';
import img4 from '../img/fashion/fashion7/4.jpg';
import img5 from '../img/fashion/fashion7/5.jpg';
import img6 from '../img/fashion/fashion7/6.jpg';
import img7 from '../img/fashion/fashion7/7.jpg';
import img8 from '../img/fashion/fashion7/8.jpg';

class Fashion7 extends React.Component {

  render() {
    return (
			<div class="box">
        <Zmage
          className="img"
          src={img3}
        />
        <Zmage
          className="img"
          src={img4}
        />
        <Zmage
          className="img"
          src={img5}
        />
        <Zmage
          className="img"
          src={img6}
        />
        <Zmage
          className="img"
          src={img7}
        />
        <Zmage
          className="img"
          src={img8}
        />
        <Zmage
          className="img"
          src={img1}
        />
        <Zmage
          className="img"
          src={img2}
        />
      </div>
		);
  }
}

export default Fashion7;