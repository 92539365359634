/*
 * @Author: Rider
 * @Date: 2020-06-09 18:18:43
 * @LastEditors: Rider
 * @LastEditTime: 2020-06-09 18:30:57
 * @Description: file content
 */ 

import React from "react";

class Portfolio extends React.Component {

  render() {
    return (
			<h1>Portfolio</h1>
		);
  }
}

export default Portfolio;